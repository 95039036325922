import React, { memo, useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import {
    afnHeaderData,
    nodeInfo
} from "src/common/constants/afn-package-details.const";
import { PageHeaderUtility } from "src/components/helpers/headers.util/headers.util";
import "./afn.component.scss"
import {
    afnInputFieldsType,
    AfnResponseType, shipmentTypeType,
    showActionBarComponentsForTableType
} from "src/pages/visibility-page/components/afn/afn.component.type";
import { afnVisibilityApi, doAfnBulkSearchRequestApiCall } from "src/common/api/afn.api";
import afnSearchResponse from "src/common/dao/afn/afn-response.dao";
import { convertSearchIdToArray, filterAndUpdateUrl } from "src/utils/utils";
import { apiSuccessMetricsPublisher, buttonClickMetricsPublisher } from "src/utils/metricUtils";
import { apiFatalsMetricsPublisher } from "src/utils/metricUtils";
import { AfnInputComponent } from "./afn-input.component";
import { PopulateScannableLegDataType } from "src/common/dao/afn/afn-dao.type";
import {
    AfnLegResultsComponent
} from "src/pages/visibility-page/components/afn/results/afn-leg-results.component";
import { AfnTableResultsComponent } from "src/pages/visibility-page/components/afn/results/afn-table-results.component";
import { BULKSEARCH_LIMIT, afnInputFieldConditions } from "src/pages/visibility-page/components/afn/afn.config";
import { rateLimitApiCall } from "src/utils/ratelimitting-utils";
import Alert, { AlertType } from "@amzn/meridian/alert/alert";
import { AFN_VISIBILITY_RATELIMITTING_CONFIG } from "src/utils/ratelimitting-configs";
import { AfnErrorResultsComponent } from "src/pages/visibility-page/components/afn/results/afn-results-error.component";
import { AfnResponseErrorObject } from "src/pages/visibility-page/components/afn/afn.component.type";
import { getDataForRegion as t } from "src/common/constants/translations.const";
import afnSearchRequest from "src/common/dao/afn/afn-request.dao";
import { katalLoggerError } from "src/logger";
import { getEmployeeAlias, getToken } from "src/auth/cognito-auth";
import Icon from "@amzn/meridian/icon";
import downloadLargeTokens from "@amzn/meridian-tokens/base/icon/download-small"
import chevronUpSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-up-small";
import Tooltip from "@amzn/meridian/tooltip";
import Button from "@amzn/meridian/button";
import { BulkSearchResultsModalComponent } from "./results/bulksearch-results-modal.component"
import { getDataForRegion as translateText } from "src/common/constants/translations.const";
import searchHistoryIcon from 'src/images/search_history.png';
import Box from "@amzn/meridian/box";
import {IS_AFTX_REQUEST} from "src/utils/aftx-utils";
import { handleDownloadFileBtnClick} from "src/components/helpers/table-renderer-util/table-renderer-util";
import { useUserPreferencesContext } from 'src/context/UserPreferencesContext';

const AfnComponent = () => {

    const afnInputFields: afnInputFieldsType = {
        format: "leg",
        nodeInfo: "",
        shipmentType: "scannable",
        bulkSearchEnabled: false,
        searchId: "",
        multipleVersionDataCheckbox: false,
        showResultsInfo: false,
        stateLoadedWithParams: false,
        isLoading: false,
        searchCount : 0,
    }

    const [afnForm, setAfnForm] = useState<afnInputFieldsType>(afnInputFields);
    const [afnMissingFields, setAfnMissingFields] = useState<Record<string, string>>({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [isSheetOpen, setIsSheetOpen] = useState(false)
    const [afnResults, setAfnResults] = useState<AfnResponseType>({ leg: undefined, table: undefined, errors: undefined });
    const [idsMap, setIdsMap] = useState<Record<string, string>>({}); // To fetech the scannable, tracking Id from formatted response
    const [toastConfig, settoastConfig] = useState({
        message: "",
        type: "success" as AlertType
    })
    const [bulkSearchResponseMessage, setBulkSearchResponseMessage] = useState({
        s3FileLink: "",
        type: "success",
        messageElement: null as any
    });
    const [showBulkSearchResults, setShowBulkSearchResults] = useState(false)
    const [searchButtonLoadingText, setSearchButtonLoadingText] = useState("")
    
    const { timezone } = useUserPreferencesContext();
    const isInitialMount = useRef(true);

    useEffect(() => {
        // Making a new search whenever timezone is changed 
        if (isInitialMount.current) {
            isInitialMount.current = false;
          } else {
            const searchIdsList = convertSearchIdToArray(afnForm.searchId);
            if(searchIdsList.length==0)
                return
            // This code will only run on subsequent renders, not on the initial render
            // Making a new search whenever timezone is changed 
            handleSubmit(false);
          }
    }, [timezone]);

    const [tableResponseForExport, settableResponseForExport] = useState<AfnResponseType>({ leg: undefined, table: undefined, errors: undefined });
    useEffect(() => {
        if (searchParams.get("shipmentType") == null) return;
        const searchId = searchParams.get("searchId")?.split(",").join("\n") || ""
        setAfnForm({
            ...afnForm,
            format: searchParams.get("format") == "table" ? "table" : "leg",
            nodeInfo: searchParams.get("nodeInfo") || "",
            shipmentType: (searchParams.get("shipmentType") as shipmentTypeType) || "scannable",
            searchId: searchId,
            stateLoadedWithParams: true,
            searchCount: convertSearchIdToArray(searchId).length,
        })
    }, []);

    useEffect(() => {
        if (afnForm.stateLoadedWithParams) {
            setAfnForm({ ...afnForm, isLoading: true })
            handleSubmit()
        }
    }, [afnForm.stateLoadedWithParams])

    useEffect(() => {
        setTimeout(() => {
            settoastConfig({
                message: "",
                type: "error"
            });
        }, 5000)

    }, [toastConfig.message])

    const getAfnIncompleteFields = (): boolean => {
        let incompleteFields: Record<string, string> = {}
        let nodeIdPattern = /^[A-Z0-9]{4,4}$/
        let inputIdLimit = afnInputFieldConditions[afnForm.shipmentType].limit
        let keyForNodeInfoError: "isNodeIdRequiredForLegFormat" | "isNodeIdRequiredForTableFormat" = afnForm.format == "leg" ? "isNodeIdRequiredForLegFormat" : "isNodeIdRequiredForTableFormat";
        let isHavingIncompleteFields: boolean = false;
        const searchIdsList = convertSearchIdToArray(afnForm.searchId);

        if (afnForm.bulkSearchEnabled == false && searchIdsList.length > inputIdLimit) {
            incompleteFields["searchId"] = t('searchLimitExceededErrorText')
                .replace('_inputIdLimit', inputIdLimit.toString());

            setAfnMissingFields({ ...incompleteFields })
            isHavingIncompleteFields = true
        }
        else if (afnForm?.searchId?.trim() == "" || afnForm.searchId == undefined) {
            incompleteFields["searchId"] = t('searchErrorText')
            setAfnMissingFields({ ...incompleteFields })
            isHavingIncompleteFields = true
        }
        if (afnForm.bulkSearchEnabled && searchIdsList.length > BULKSEARCH_LIMIT) {
            incompleteFields["searchId"] = "Cannot search more than " + BULKSEARCH_LIMIT + " ids at a time in bulk search";
            setAfnMissingFields({ ...incompleteFields })
            isHavingIncompleteFields = true
        } else if (afnForm.searchId == "" || afnForm.searchId == undefined) {
            incompleteFields["searchId"] = t('searchErrorText')
            setAfnMissingFields({ ...incompleteFields })
            isHavingIncompleteFields = true
        }
        // removing Node info field for AFT-X
        if (!IS_AFTX_REQUEST && afnForm.nodeInfo == "" && afnInputFieldConditions[afnForm.shipmentType][keyForNodeInfoError]) {
            incompleteFields["nodeInfo"] = "Node Id cannot be empty for " + afnForm.shipmentType + " in " + afnForm.format + " format"
            setAfnMissingFields({ ...incompleteFields })
            isHavingIncompleteFields = true
        } else if (!IS_AFTX_REQUEST && !nodeIdPattern.test(afnForm.nodeInfo) && afnInputFieldConditions[afnForm.shipmentType][keyForNodeInfoError]) {
            incompleteFields["nodeInfo"] = "Node info is not in required format"
            setAfnMissingFields({ ...incompleteFields })
            isHavingIncompleteFields = true
        }
        setAfnMissingFields({ ...incompleteFields })
        return isHavingIncompleteFields;
    }

    const handleSubmit = (getLairManifestDetails:boolean = false) => {
        if(getLairManifestDetails){
            setSearchButtonLoadingText("Exporting to CSV")
        } else {
            setSearchButtonLoadingText("")
        }
        setBulkSearchResponseMessage({
            s3FileLink: '',
            messageElement: null,
            type: "warning"
        })
        if (getAfnIncompleteFields()) {
            setAfnForm({ ...afnForm, isLoading: false })
            return
        }
        const params = {
            format: afnForm.format,
            nodeInfo: afnForm.nodeInfo,
            shipmentType: afnForm.shipmentType,
            searchId: afnForm.searchId.split("\n").join(",")
        }
        buttonClickMetricsPublisher.publishCounterMonitor(afnForm.format, 1)
        buttonClickMetricsPublisher.publishCounterMonitor(afnForm.shipmentType, 1)
        buttonClickMetricsPublisher.publishCounterMonitor("AFN-Search", 1)

        setAfnForm({ ...afnForm, isLoading: true })
        if (afnForm.bulkSearchEnabled) {
            const bulkSerachRequestInput = {
                "headers": {
                    "Authorization": getToken()
                },
                "body": {
                    "type": "CREATE",
                    "userLogin": getEmployeeAlias(),
                    "page": "afn",
                    "format": "Leg",
                    "nodeId": afnForm.nodeInfo,
                    "searchType": afnForm.shipmentType,
                    ...new afnSearchRequest(afnForm, true).request
                }
            }

            if (afnForm.format == "leg") {
                bulkSerachRequestInput.body["showAllNodesData"] = true
            }
            setAfnForm({ ...afnForm, isLoading: true })
            doAfnBulkSearchRequestApiCall(bulkSerachRequestInput).then(resp => {
                setBulkSearchResponseMessage({
                    s3FileLink: "",
                    messageElement: <ul style={{ paddingTop: '5px', paddingLeft: '3%', paddingBottom: '5px', margin: '0', marginBottom: '10px' }} >
                        <li className="bulksearch_sucess_resp">
                            {translateText('bulkSearchSubmittedText').split("_button_placeholder")[0]}
                            <Tooltip position="top" title="Click to view bulk search history">
                                <img
                                    style={{
                                        cursor: 'pointer',
                                        margin: '2px 2px -2px 2px'
                                    }}
                                    src={searchHistoryIcon}
                                    width="20px"
                                    alt="Bulk Search Feature Launch Icon"
                                    onClick={() => {
                                        setShowBulkSearchResults(!showBulkSearchResults)
                                    }}
                                />
                            </Tooltip>
                            {translateText('bulkSearchSubmittedText').split("_button_placeholder")[1]}
                        </li>
                        <li className="bulksearch_sucess_resp" >{translateText('bulkSearchExpectedTimeText')}</li>
                        <li className="bulksearch_sucess_resp">{translateText('bulkSearchRequestIdText')
                            .replace('_request_id', resp.request_id.substring(0, 3))}</li>

                    </ul>,
                    type: "success"
                })
                setAfnForm({ ...afnForm, "showResultsInfo": false, isLoading: false })

            }).catch(err => {
                setBulkSearchResponseMessage({
                    s3FileLink: err.response.data.item.s3_file_link,
                    messageElement: `${err.response.data.errorMessage}`,
                    type: "warning"
                })
                setAfnForm({ ...afnForm, "showResultsInfo": false, isLoading: false })
            })

            return;
        }
        // Non bulk search flow continues
        if (!afnForm.bulkSearchEnabled) {
            /* 
            Avoid updating the URL for BulkSearch requests. Bulk Search requests may contain more than 1000 IDs per 
            search, causing the URL length to exceed the limits of most web servers (such as API gateways and local 
            React dev server). This will result in HTTP request failures with  status code 431
            */
            filterAndUpdateUrl(window.location.href, params, document.title)
        }
        var rateLimittingResponse = rateLimitApiCall({
            ...AFN_VISIBILITY_RATELIMITTING_CONFIG,
            callWeight: convertSearchIdToArray(params.searchId).length
        })
        if (rateLimittingResponse != true) {
            setAfnForm({ ...afnForm, isLoading: false })
            settoastConfig({
                message: rateLimittingResponse,
                type: "error"
            });
            return;
        }
      
        setAfnForm({ ...afnForm, "showResultsInfo": false, isLoading: true })
        // Getting manifest details for export to csv or for table format
        afnVisibilityApi(afnForm, getLairManifestDetails|| afnForm.format=="table").then((resp: afnSearchResponse) => {
            apiSuccessMetricsPublisher.publishCounterMonitor("AFN-Success", 1);
            settableResponseForExport({ ...afnResults, leg: undefined, table: resp.tableResponse.table })
            //Empty Response 
            if (resp.response.leg == undefined && resp.response.table == undefined) {
                setAfnResults({ leg: undefined, table: undefined, errors: resp.response.errors })
            } else if (afnForm.format == "leg" && (afnForm.shipmentType == "scannable" || afnForm.shipmentType == "container" || afnForm.shipmentType == "shipment" || afnForm.shipmentType == "transshipment" || afnForm.shipmentType == "trailer" || afnForm.shipmentType == "vrid")) {
                let result: Array<PopulateScannableLegDataType> = [];
                setIdsMap(resp.idsMap)
                resp.response.leg!.forEach((currentPackage: PopulateScannableLegDataType) => {
                    result.push({
                        enrichedTrackingIdInfo: currentPackage.enrichedTrackingIdInfo,
                        esmmInfo: currentPackage.esmmInfo,
                        legInfo: currentPackage.legInfo,
                        nodeDetails: currentPackage.nodeDetails,
                        packageDetails: currentPackage.packageDetails,
                        toolLinks: currentPackage.toolLinks
                    })
                })
                if(getLairManifestDetails){
                    // Exporting the results in CSV format including the lair details
                    const table:any = resp.tableResponse.table ;
                    handleDownloadFileBtnClick(table.headers,table.data)
                    getLairManifestDetails = false;
                }
                setAfnResults({ ...afnResults, leg: result, table: undefined, errors: resp.response.errors })
            }
            else {
                setAfnResults({ ...afnResults, leg: undefined, table: (resp.response as AfnResponseType).table, errors: resp.response.errors })
            }
            setAfnForm({ ...afnForm, "showResultsInfo": true, isLoading: false })

        })
            .catch((error) => {
                katalLoggerError(window.location.href, "AfnVisibilityApi ==> Error", error);
                let errorsObject: AfnResponseErrorObject = {
                    errorMessage: "Error occurred, the inputs provided are not returning any results for ",
                    ids: [],
                    isError: false
                };
                errorsObject.ids.push("Error occurred, the inputs provided are not returning any results for")
                setAfnResults({ ...afnResults, leg: undefined, table: undefined, errors: errorsObject })
                setAfnForm({ ...afnForm, "showResultsInfo": true, isLoading: false })
                //Todo: Show custom error messages based on error provided
                apiFatalsMetricsPublisher.publishCounterMonitor("AFN-Failure", 1)
            }
            )
    }

    const showActionBarComponentsForTable: showActionBarComponentsForTableType = {
        "showActionBar": false,
        "showSearchBar": false,
        "showFilters": false,
        "showDownloadButton": false,
        "showColumnFilters": true
    }

    const shipmentMethodMapper = () => {
        const { leg, table } = afnResults;
        if (table) return <AfnTableResultsComponent showActionBarComponentsForTable={{
            ...showActionBarComponentsForTable,
            showActionBar: true,
        }} tableResponseForExport={afnResults} afnResults={table} />;
        if (leg) return <AfnLegResultsComponent tableResponseForExport={tableResponseForExport} afnResults={leg} afnForm={afnForm} handleSubmit = {handleSubmit} idsMap={idsMap} {...{ showActionBarComponentsForTable }} />;
        return null;
    };

    const showErrorTable = () => {
        return <AfnErrorResultsComponent
            errorResults={afnResults.errors!} />
    }
    const scrollRef = useRef<HTMLDivElement>(document.createElement('div'));
    const [showTopBtn, setShowTopBtn] = useState(false);

    useEffect(() => {
        const mainElement = document.getElementById('app-layout-content-1')!;
        function scrollHandler() {
            let scrollPosition = 0
            if (mainElement)
                scrollPosition = mainElement.scrollTop;
            setShowTopBtn(scrollPosition > 40)
        }

        mainElement.addEventListener("scroll", scrollHandler);

        return () => {
            mainElement.removeEventListener('scroll', scrollHandler)
        }
    }, []);

    const backToTopButton = () => {

        return (showTopBtn &&
            <div style={{
                position: 'fixed',
                bottom: '8%',
                right: '5%'
            }}>
                <Button onClick={() => scrollRef.current.scrollIntoView({ behavior: 'smooth' })} circular={true} size={"large"}>
                    <Icon tokens={chevronUpSmallTokens} />
                </Button>
            </div>
        );
    };





    return (
        <React.Fragment>
            <div ref={scrollRef}>
                <div className="afn-container">
                    <PageHeaderUtility pageTitle={afnHeaderData.pageTitle} myKey={afnHeaderData.key} />
                    {showBulkSearchResults ? <BulkSearchResultsModalComponent open={showBulkSearchResults} setOpen={setShowBulkSearchResults} /> : ''}
                    <AfnInputComponent
                        afnForm={afnForm}
                        setAfnForm={setAfnForm}
                        afnMissingFields={afnMissingFields}
                        setAfnMissingFields={setAfnMissingFields}
                        setAfnResults={setAfnResults}
                        isSheetOpen={isSheetOpen}
                        setIsSheetOpen={setIsSheetOpen}
                        handleSubmit={handleSubmit}
                        getAfnIncompleteFields={getAfnIncompleteFields}
                        data-cyid="afn-input-element-id"
                        searchButtonLoadingText={searchButtonLoadingText}
                    />
                    <div style={{ "marginTop": "2%", "marginBottom": "1%" }} className={"package-search-results"}>
                        {
                            bulkSearchResponseMessage.messageElement != null &&
                            <Alert type={bulkSearchResponseMessage.type as AlertType} size="medium" >
                                {bulkSearchResponseMessage.messageElement} &nbsp;
                                {bulkSearchResponseMessage.s3FileLink && <a href={bulkSearchResponseMessage.s3FileLink}>
                                    <Tooltip position="end" title="Download previous response file">
                                        <Icon tokens={downloadLargeTokens} />
                                    </Tooltip> </a>}
                            </Alert>
                        }
                    </div>

                    {afnForm.showResultsInfo &&
                        ((afnResults as AfnResponseType).leg == undefined && afnResults.table == undefined ?
                            <div>
                                {showErrorTable()}
                            </div> :
                            <>
                                {((afnResults as AfnResponseType).errors && (afnResults.errors != undefined && afnResults.errors.isError) ?
                                    <div>{showErrorTable()}</div>
                                    : <></>
                                )}
                                <div>
                                    <div className={"package-search-results"}>{shipmentMethodMapper()} </div>
                                </div>

                            </>
                        )}
                </div>
                {toastConfig.message.length >= 1 && <div className="rate_limit_alert" >
                    <Alert
                        onClose={() => {
                            settoastConfig({
                                message: "",
                                type: "error" as AlertType
                            })
                        }}
                        type={toastConfig.type}
                        size="large"
                    >{toastConfig.message}</Alert>
                </div>
                }
                {backToTopButton()}
            </div>
        </React.Fragment>
    )
}

export default memo(AfnComponent)