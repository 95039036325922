import { STR_LANGUAGE, STR_REGION, STR_TIMEZONE } from "src/common/constants/utils.const";
import { languageMenu } from "src/common/constants/header.const";
import { AFTX_WAREHOUSE_ID, IS_AFTX_REQUEST } from "src/utils/aftx-utils";
import DOMPurify from "dompurify";


export const toQueryString = (params: Record<string, any>): string => {
	const urlParams: string = new URLSearchParams(params).toString()
	return urlParams
}

export const getItemFromCache = (key: string): any => {
    const cachedValue = localStorage.getItem(key);
	return cachedValue === null ? null : JSON.parse(cachedValue)
}

export const setItemInCache = (key: string, value: any): void => {
    const objectToCache = value;
    localStorage.setItem(key, JSON.stringify(objectToCache));
}

export const filterAndUpdateUrl = (pathname: string, newParams: Record<string, string>, name: string, reloadPage?: boolean) => {
	var url = new URL(pathname)
	if (IS_AFTX_REQUEST) {
		if (AFTX_WAREHOUSE_ID) {
			url.searchParams.set('nodeInfo', AFTX_WAREHOUSE_ID);
		} else {
			// Remove the node parameter if AFTX_WAREHOUSE_ID is undefined
			url.searchParams.delete('nodeInfo');
		}
	}

	Object.keys(newParams).map((key: string) => {
		url.searchParams.set(key, newParams[key])
	})
	var params = url.searchParams
	// condition check to avoid duplication entry
	if (window.location.href === url.toString()) return;
	window.history.pushState("", name, url.pathname + "?" + params);
}

export const getLangAndRegionQueryParam = (pathSearch: string) => {
	const params: any = getQueryParamsMap(pathSearch)
	return `?${STR_LANGUAGE}=${params[STR_LANGUAGE]}&${STR_REGION}=${params[STR_REGION]}`
}

export const getQueryParamsMap = (pathSearch: string) => {
	const params: any = new Proxy(new URLSearchParams(pathSearch), {
		get: (queryParams, prop: any) => queryParams.get(prop)
	});

	return params
}

export const getEECSApiForRegion = (): string => {
	const urls = "NA::https://na.eagleeye-integ-api.ats.amazon.dev,EU::https://eu.eagleeye-integ-api.ats.amazon.dev,FE::https://test.amazon.com,CN::https://fe.eagleeye-integ-api.ats.amazon.dev".split(',')
	const urlsMap: Record<string, string> = {}
	urls.map((regionUrlString: string) => {
		const [region, url] = regionUrlString.split('::');
		urlsMap[region] = url
	})
	const region = getValidRegion()
	return urlsMap[region]
}

export const getEECSAPIEndpointForRegion = ( region:string ): string =>{
	const urls = "NA::https://na.eagleeye-integ-api.ats.amazon.dev,EU::https://eu.eagleeye-integ-api.ats.amazon.dev,FE::https://test.amazon.com,CN::https://fe.eagleeye-integ-api.ats.amazon.dev".split(',')
	const urlsMap: Record<string, string> = {}
	urls.map((regionUrlString: string) => {
		const [region, url] = regionUrlString.split('::');
		urlsMap[region] = url
	})
	return urlsMap[region]
}

export const getRegionRealm =(): string => {
    const region = getValidRegion()
    return region+"Amazon"
}

export const getRealmForRegion = (region: string): string => {
	let map = new Map();
    map.set('na', 'us-east-1')
    map.set('eu', 'eu-west-1')
    map.set('fe', 'us-west-2')
    map.set('jp', 'us-west-2')
   	return map.get(region);
}

export const getValidRegion = ():string=>{
	let Regions = ['NA','EU','FE','CN'];

	const regionParam = getQueryParamsMap(window.location.search).region;
    // We can also emit metrics when wrong region is provided in the URL and have it emitted
	// Currently when wrong region is provided in the URL params, it will be defaulted to NA region
	const region = Regions.includes(regionParam) ? regionParam : 'NA';
	return region;
}

export const addExtraSpace = (str:string,index:number):string =>{
	let result = ""
	for (let pos = 0; pos < str.length; pos++) {
		if (pos % index === 0 && pos !== 0) {
		  result += " "; // add space character
		}
		result += str[pos];
	  }
	return result
}

export const isStringNumber = (str:string):boolean =>{
	return /^\d+$/.test(str);
}

export const isStringAlphaNumeric = (str:string):boolean =>{
	const regex = /^[a-zA-Z0-9]+$/;
  	return regex.test(str);
}

export const getRealmFromUrl = (): string => {
	const realm = getQueryParamsMap(window.location.search).region || 'NA'
	return realm.toLocaleLowerCase()
}

export const getLanguageFromUrl = ():string => {
	const l = getQueryParamsMap(window.location.search).lang || 'EN'
	let lang:string = 'en_US'
	for( const item of languageMenu.Items )
	{
		if(item.visibleText === l)
		{
			lang = item.value
			break;
		}
	}
	return lang
}

export const getPreferredTimezone = (): string => {
	return localStorage.getItem(STR_TIMEZONE) || "UTC"
}

export const setPreferredTimezone = (timezone:string) => {
	localStorage.setItem(STR_TIMEZONE, timezone)
}

export const sanitizeInput = (searchId :string | undefined) =>{
    searchId=searchId ? DOMPurify.sanitize(searchId, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] }):"";
    return searchId
}

export const convertSearchIdToArray = (searchId: string): string[] => {
	const delimiters = /[\n\t,; ]+/;
	const sanitizedSearchId = sanitizeInput(searchId);
	return Array.from(
	  new Set(sanitizedSearchId.trim().split(delimiters).filter(Boolean))
	);
};