import { afnInputFieldsType } from "src/pages/visibility-page/components/afn/afn.component.type";
import { convertSearchIdToArray, getPreferredTimezone } from "src/utils/utils";

interface BaseRequest {
  timezone: string;
  showAllNodesData?: boolean;
}

interface ScannableRequest extends BaseRequest {
  scannableIds: string[];
  multipleVersions: boolean;
  getLairManifestDetails: boolean;
}

interface ContainerRequest extends BaseRequest {
  containerIds: string[];
  nodeId: string;
}

interface TransshipmentRequest extends BaseRequest {
  containerIds: string[];
}

interface ShipmentRequest extends BaseRequest {
  shipmentIds: string[];
  multipleVersions: boolean;
  getLairManifestDetails: boolean;
}

interface ResourceIdRequest extends BaseRequest {
  resourceId: string;
  nodeId: string;
}

interface PkgLegDetailRequest extends BaseRequest {
  trackingIds: string[];
}

interface TrailerRequest extends BaseRequest {
  trailerIds: string[];
  nodeId: string;
}

interface VridRequest extends BaseRequest {
  vrids: string[];
  nodeId: string;
}

type AfnSearchRequestType =
  | ScannableRequest
  | ContainerRequest
  | TransshipmentRequest
  | ShipmentRequest
  | ResourceIdRequest
  | PkgLegDetailRequest
  | TrailerRequest
  | VridRequest;
class afnSearchRequest {
  request: AfnSearchRequestType;

  constructor(afnForm: afnInputFieldsType, getLairManifestDetails: boolean) {
    const baseRequest: BaseRequest = {
      timezone: getPreferredTimezone(),
    };

    const searchIds = convertSearchIdToArray(afnForm.searchId);

    switch (afnForm.shipmentType) {
      case "scannable":
        this.request = {
          ...baseRequest,
          scannableIds: searchIds,
          multipleVersions: afnForm.multipleVersionDataCheckbox,
          getLairManifestDetails,
        };
        break;
      case "container":
        this.request = {
          ...baseRequest,
          containerIds: searchIds,
          nodeId: afnForm.nodeInfo,
        };
        break;
      case "transshipment":
        this.request = {
          ...baseRequest,
          containerIds: searchIds,
        };
        break;
      case "shipment":
        this.request = {
          ...baseRequest,
          shipmentIds: searchIds,
          multipleVersions: afnForm.multipleVersionDataCheckbox,
          getLairManifestDetails,
        };
        break;
      case "resourceId":
        this.request = {
          ...baseRequest,
          resourceId: afnForm.searchId,
          nodeId: afnForm.nodeInfo,
        };
        break;
      case "pkglegdetail":
        this.request = {
          ...baseRequest,
          trackingIds: searchIds,
        };
        break;
      case "trailer":
        this.request = {
          ...baseRequest,
          trailerIds: searchIds,
          nodeId: afnForm.nodeInfo,
        };
        break;
      case "vrid":
        this.request = {
          ...baseRequest,
          vrids: searchIds,
          nodeId: afnForm.nodeInfo,
        };
        break;
    }
  }
}

export default afnSearchRequest;