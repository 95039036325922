import { sanitizeInput } from "src/utils/utils";
class preSlamSearchRequest {
    shipmentId: string;
    FC: string;

    constructor(shipmentId: string, FC: string){
        this.shipmentId = sanitizeInput(shipmentId);
        this.FC = sanitizeInput(FC);
    }
}

export default preSlamSearchRequest;