import React, { createContext, useContext, useState, ReactNode } from "react"
import { getPreferredTimezone } from "src/utils/utils";
import { emitTimezoneMetrics } from "src/utils/userPreferencesMetrics";

// Defining the structure of user preferences we can add more here later
interface UserPreferencesContextType {
  timezone: string
  setTimezone: (timezone: string) => void;
}

const UserPreferencesContext = createContext<UserPreferencesContextType | undefined>(undefined);

// Creating a custom hook to conveniently access user preferences in components
export const useUserPreferencesContext = () => {
    const context = useContext(UserPreferencesContext);

    // Ensure that this hook is used within a UserPreferencesProvider
    if (context === undefined) {
      throw new Error('useUserPreferences must be used within a UserPreferencesProvider');
    }
    return context;
};

// Create a provider component to wrap the application
interface UserPreferencesProviderProps {
  children: ReactNode; 
}

// Create a provider component to wrap the application
export const UserPreferencesProvider: React.FC<UserPreferencesProviderProps> = (props: any) => {
    // Use the state hook to manage user preferences
    let cachedTimeZone = getPreferredTimezone();
    const [timezone, setTimezone] = useState<string>(cachedTimeZone ?? 'UTC');

    React.useEffect(() => {
      // Emit metric for initial timezone
      emitTimezoneMetrics.activeUserTimezone(timezone)
  }, []);
  
    // Creating an object that matches the context type
    const contextValue: UserPreferencesContextType = {
        timezone,
        setTimezone,
    };

    return (
      // Provide the user preferences and the function to set them to the context
      <UserPreferencesContext.Provider value={contextValue}>
        {props.children}
      </UserPreferencesContext.Provider>
    );
};


