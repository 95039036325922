import { timezoneMetricsPublisher } from './metricUtils';

export const emitTimezoneMetrics = {
    // Track when user changes timezone preference
    timezoneChange: (previousTimezone: string, newTimezone: string) => {
        timezoneMetricsPublisher.publishCounterMonitor(
            `timezone_change.${previousTimezone}_to_${newTimezone}`, 
            1
        );
    },

    // Track active timezone used
    activeUserTimezone: (timezone: string) => {
        timezoneMetricsPublisher.publishCounterMonitor(
            `timezone_initial_setting.${timezone}`, 
            1
        );
    },
};
