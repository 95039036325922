export const FORMAT_DATE = "YYYY-MM-DD"
export const SERVICE_EXECUTE_API = "execute-api"
export const METHOD_GET = "GET"
export const PATH_PROD = "/prod"
export const AMAZON_AWS = "amazonaws"
export const API_IAM_ROLE_MAPPING: Record<string, string> = {
	"NA::https://na.eagleeye-integ-api.ats.amazon.dev,EU::https://eu.eagleeye-integ-api.ats.amazon.dev,FE::https://test.amazon.com,CN::https://fe.eagleeye-integ-api.ats.amazon.dev": "{{EECSApiAccessIamRoleArn}}"
}
export const EECS_BASE_API = "NA::https://na.eagleeye-integ-api.ats.amazon.dev,EU::https://eu.eagleeye-integ-api.ats.amazon.dev,FE::https://test.amazon.com,CN::https://fe.eagleeye-integ-api.ats.amazon.dev"
export const EECS_API_PROXY_PATH = 'eecs-proxy'
export const STR_LANGUAGE = "lang"
export const STR_REGION = "region"
export const STR_TIMEZONE = "timezone"
export const CY_SELECTORS: string[] = ['data-']
// if user doesnt have any table column preferences , we will show below columns in all afn searches 
export const DEFAULT_TABLE_COLS = [{
	field: 'label',
	title: 'Encrypted Shipment Id',
}, {
	field: 'nodeId',
	title: 'Search node',
}, {
	field: 'legInfo',
	title: 'Legs',
}, {
	field: 'hasArrived',
	title: 'Has Arrived',
}, {
	field: 'hasDeparted',
	title: 'Has Departed',
}, {
	field: 'package_type',
	title: 'Package Type',
}, {
	field: 'trackingId2',
	title: 'Reverse Tracking ID',
}]
export const AFN_PATH_NAMES: string[] = ["/search", "/"];

export const WHITE = "#FFFFFF"
export const TEAL = "#045C69"