import { sanitizeInput } from "src/utils/utils";
import { xorRedriveRequestType, xorRedrivePayloadType } from "./xor-redrive-dao-type";
class xorRedriveRequest {
    xorRedrivePayload: xorRedrivePayloadType
    request: xorRedriveRequestType

    constructor(xorRedrivePayload: xorRedrivePayloadType) {
        this.xorRedrivePayload = xorRedrivePayload
        this.request = this.builderXorRedriveRequest();
    }

    builderXorRedriveRequest(): xorRedriveRequestType {
        this.xorRedrivePayload.trackingId=sanitizeInput(this.xorRedrivePayload.trackingId)
        var xorRedriveRequest: xorRedriveRequestType = {
            //requestId: this.xorRedrivePayload.requestId ?  this.xorRedrivePayload.requestId : "",
            trackingId: this.xorRedrivePayload.trackingId ? this.xorRedrivePayload.trackingId : "",
            isBulkRedrive: this.xorRedrivePayload.isBulkRedrive,
            fileContentEncoded: this.xorRedrivePayload.fileContentEncoded,
            requestId:this.xorRedrivePayload.requestId ? this.xorRedrivePayload.requestId : "",
            requesterName: this.xorRedrivePayload.requesterName ? this.xorRedrivePayload.requesterName : "",
            xorRedriveStage: this.xorRedrivePayload.xorRedriveStage,
            xorRedriveRegion: this.xorRedrivePayload.xorRedriveRegion ? this.xorRedrivePayload.xorRedriveRegion : "",
        }
        return xorRedriveRequest;
    }
}

export default xorRedriveRequest