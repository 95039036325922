import React, { useEffect, useState } from "react";
import Select, { SelectOption } from "@amzn/meridian/select";
import Text from "@amzn/meridian/text";
import { timezones } from "src/utils/timezones";
import { setPreferredTimezone, getPreferredTimezone } from "src/utils/utils";
import { UserGuideModal } from "../user-guide-modal/user-guide-modal";
import { TIMEZONE_CONVERSION_USER_GUIDE } from "src/pages/visibility-page/components/afn/afn.config";
import { useUserPreferencesContext } from 'src/context/UserPreferencesContext';
import newLogo from 'src/images/new_icon.png';
import Row from "@amzn/meridian/row";
import { emitTimezoneMetrics } from "src/utils/userPreferencesMetrics";

export const TimezoneDropdown = () => {
  // Access user preferences and setUserPreferences function from the context
  // Use the state hook to manage user preferences
  const { timezone, setTimezone } = useUserPreferencesContext();
  const [searchQuery, setSearchQuery] = useState();
  const [showUserGuide, setShowUserGuide] = useState(false);

  useEffect(() => {
    // Check if the current timezone is different from the default or previously set timezone
    const defaultTimezone = getPreferredTimezone() || "UTC";
    if (timezone !== defaultTimezone) {
      setShowUserGuide(true);
    }
  }, [timezone]);

  const handleTimezoneChange = (newTimezone: string) => {
    // Emit metric for timezone change
    emitTimezoneMetrics.timezoneChange(timezone,newTimezone)
    setPreferredTimezone(newTimezone);
    setTimezone(newTimezone);
    setShowUserGuide(true);  // Show the user guide when timezone changes
  };

  // Here we filter out options by creating an escaped regular expression
  // out of the search query and test it against each option label
  const searchRegExp = new RegExp((searchQuery || ""), "i");

  const matchedOptions = timezones.filter(
    (timezone) =>
      !searchQuery ||
      searchRegExp.test(timezone.label) ||
      searchRegExp.test(timezone.offset) ||
      searchRegExp.test(timezone.format)
  );

  return (
      <Row>
          <img style={{ marginLeft: '-5px', marginTop: "5px" }}
                                  className="timezone_newLogo" src={newLogo}
                                  width="25px" alt="TimeZone change Feature Launch Icon" /> 
          <Text type="b200">Timezone:</Text>
          {showUserGuide && <UserGuideModal {...TIMEZONE_CONVERSION_USER_GUIDE} />}
          <Select
            value={timezone}
            onChange={handleTimezoneChange}
            searchQuery={searchQuery}
            onSearch={setSearchQuery}
            placeholder="Select the timezone"
            width={160}
            size="small"
            popoverMaxHeight={300}
            id="timezone-dropdown"
            valueLabel={(options) => {
              return options.map(
                (option: { label: string; value: string }) =>
                  option.label.split(" ")[1]
              );
            }}
            data-cyid="timezone-select"
          >
            {matchedOptions.length > 0 ? (
            matchedOptions.map((timezone) => (
              <SelectOption
                key={timezone.label}
                value={timezone.label}
                label={
                  timezone.label + " " + timezone.format + "/" + timezone.offset
                }
              />
            ))
          ) : (
            <SelectOption value={timezone} label="No results" />
          )}
          </Select>
        </Row>
  );
};
