export type timezoneType = {
  label: string
  format: string
  offset: string
}[]

export const timezones: timezoneType = [
  { label: "Pacific/Midway", format: "SST", offset: "UTC-11:00" },
  { label: "Pacific/Honolulu", format: "HST", offset: "UTC-10:00" },
  { label: "America/Anchorage", format: "AKDT", offset: "UTC-8:00" },
  { label: "America/Los_Angeles", format: "PDT", offset: "UTC-7:00" },
  { label: "America/Phoenix", format: "MST", offset: "UTC-7:00" },
  { label: "America/Vancouver", format: "PDT", offset: "UTC-7:00" },
  { label: "America/Denver", format: "MDT", offset: "UTC-6:00" },
  { label: "America/Bogota", format: "COT", offset: "UTC-5:00" },
  { label: "America/Chicago", format: "CDT", offset: "UTC-5:00" },
  { label: "America/Lima", format: "PET", offset: "UTC-5:00" },
  { label: "America/Mexico_City", format: "CDT", offset: "UTC-5:00" },
  { label: "America/Caracas", format: "VET", offset: "UTC-4:00" },
  { label: "America/Montreal", format: "EDT", offset: "UTC-4:00" },
  { label: "America/New_York", format: "EDT", offset: "UTC-4:00" },
  { label: "America/Toronto", format: "EDT", offset: "UTC-4:00" },
  { label: "America/Buenos_Aires", format: "ART", offset: "UTC-3:00" },
  { label: "America/Halifax", format: "ADT", offset: "UTC-3:00" },
  { label: "America/Santiago", format: "CLT", offset: "UTC-3:00" },
  { label: "America/Sao_Paulo", format: "BRT", offset: "UTC-3:00" },
  { label: "America/St_Johns", format: "NDT", offset: "UTC-2:30" },
  { label: "Atlantic/South_Georgia", format: "GST", offset: "UTC-2:00" },
  { label: "Atlantic/Azores", format: "AZOT", offset: "UTC-1:00" },
  { label: "Atlantic/Reykjavik", format: "GMT", offset: "UTC+0:00" },
  { label: "UTC", format: "UTC", offset: "UTC+0:00" },
  { label: "Europe/Dublin", format: "IST", offset: "UTC+1:00" },
  { label: "Europe/London", format: "BST", offset: "UTC+1:00" },
  { label: "Africa/Cairo", format: "EET", offset: "UTC+2:00" },
  { label: "Europe/Amsterdam", format: "CEST", offset: "UTC+2:00" },
  { label: "Europe/Berlin", format: "CEST", offset: "UTC+2:00" },
  { label: "Europe/Brussels", format: "CEST", offset: "UTC+2:00" },
  { label: "Europe/Copenhagen", format: "CEST", offset: "UTC+2:00" },
  { label: "Europe/Madrid", format: "CEST", offset: "UTC+2:00" },
  { label: "Europe/Paris", format: "CEST", offset: "UTC+2:00" },
  { label: "Europe/Rome", format: "CEST", offset: "UTC+2:00" },
  { label: "Europe/Stockholm", format: "CEST", offset: "UTC+2:00" },
  { label: "Europe/Vienna", format: "CEST", offset: "UTC+2:00" },
  { label: "Africa/Nairobi", format: "EAT", offset: "UTC+3:00" },
  { label: "Asia/Baghdad", format: "AST", offset: "UTC+3:00" },
  { label: "Asia/Beirut", format: "EEST", offset: "UTC+3:00" },
  { label: "Asia/Jerusalem", format: "IDT", offset: "UTC+3:00" },
  { label: "Asia/Kuwait", format: "AST", offset: "UTC+3:00" },
  { label: "Asia/Qatar", format: "AST", offset: "UTC+3:00" },
  { label: "Asia/Riyadh", format: "AST", offset: "UTC+3:00" },
  { label: "Europe/Athens", format: "EEST", offset: "UTC+3:00" },
  { label: "Europe/Bucharest", format: "EEST", offset: "UTC+3:00" },
  { label: "Europe/Istanbul", format: "TRT", offset: "UTC+3:00" },
  { label: "Europe/Moscow", format: "MSK", offset: "UTC+3:00" },
  { label: "Asia/Dubai", format: "GST", offset: "UTC+4:00" },
  { label: "Asia/Yerevan", format: "AMT", offset: "UTC+4:00" },
  { label: "Asia/Kabul", format: "AFT", offset: "UTC+4:30" },
  { label: "Asia/Tehran", format: "IRST", offset: "UTC+4:30" },
  { label: "Asia/Karachi", format: "PKT", offset: "UTC+5:00" },
  { label: "Asia/Tashkent", format: "UZT", offset: "UTC+5:00" },
  { label: "Asia/Kolkata", format: "IST", offset: "UTC+5:30" },
  { label: "Asia/Kathmandu", format: "NPT", offset: "UTC+5:45" },
  { label: "Asia/Almaty", format: "ALMT", offset: "UTC+6:00" },
  { label: "Asia/Dhaka", format: "BDT", offset: "UTC+6:00" },
  { label: "Asia/Yangon", format: "MMT", offset: "UTC+6:30" },
  { label: "Asia/Bangkok", format: "ICT", offset: "UTC+7:00" },
  { label: "Asia/Hong_Kong", format: "HKT", offset: "UTC+8:00" },
  { label: "Asia/Shanghai", format: "CST", offset: "UTC+8:00" },
  { label: "Asia/Singapore", format: "SGT", offset: "UTC+8:00" },
  { label: "Asia/Taipei", format: "CST", offset: "UTC+8:00" },
  { label: "Australia/Perth", format: "AWST", offset: "UTC+8:00" },
  { label: "Asia/Seoul", format: "KST", offset: "UTC+9:00" },
  { label: "Asia/Tokyo", format: "JST", offset: "UTC+9:00" },
  { label: "Australia/Adelaide", format: "ACDT", offset: "UTC+9:30" },
  { label: "Australia/Darwin", format: "ACST", offset: "UTC+9:30" },
  { label: "Australia/Brisbane", format: "AEST", offset: "UTC+10:00" },
  { label: "Australia/Melbourne", format: "AEST", offset: "UTC+10:00" },
  { label: "Australia/Sydney", format: "AEST", offset: "UTC+10:00" },
  { label: "Pacific/Guam", format: "ChST", offset: "UTC+10:00" },
  { label: "Australia/Lord_Howe", format: "LHST", offset: "UTC+10:30" },
  { label: "Pacific/Guadalcanal", format: "SBT", offset: "UTC+11:00" },
  { label: "Pacific/Auckland", format: "NZST", offset: "UTC+12:00" },
  { label: "Pacific/Fiji", format: "FJT", offset: "UTC+12:00" },
  { label: "Pacific/Tongatapu", format: "TOT", offset: "UTC+13:00" },
  { label: "Pacific/Kiritimati", format: "LINT", offset: "UTC+14:00" }
]
