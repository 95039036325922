import React from 'react';
import AppLayoutComponent from 'src/components/layouts/app-shell-layout/app-shell.layout'
import { AuthContext, AuthenticationContext } from '../context/AuthenticationContext';
import { CommonAttributeContext } from 'src/context/CommonDataContext';
import { UserPreferencesProvider } from 'src/context/UserPreferencesContext';

export class App extends React.Component {
    render() {
        return (
            <AuthenticationContext>
                <UserPreferencesProvider>
                    <CommonAttributeContext>
                        <AppLayoutComponent />
                    </CommonAttributeContext>
                </UserPreferencesProvider>
            </AuthenticationContext>
        );
    }
}

export default App;